var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "banner-box" }, [
            _c("img", {
              attrs: { src: "https://www.achencybe.com/img/pc/form/4-1.jpg" },
            }),
            _c("div", { staticClass: "banner-text" }, [
              _c("div", [_vm._v(_vm._s(_vm.$t("business.des_1")))]),
              _c("p", [_vm._v(" " + _vm._s(_vm.$t("business.des_2")) + " ")]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "icon-box" },
            _vm._l(_vm.iconList, function (item) {
              return _c("div", { key: item.label }, [
                _c("img", { attrs: { src: item.icon } }),
                _c("div", [_vm._v(_vm._s(_vm.$t(item.label)))]),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "tips-box" },
            _vm._l(_vm.tipList, function (item) {
              return _c("p", { key: item }, [_vm._v(_vm._s(_vm.$t(item)))])
            }),
            0
          ),
          _c("div", { staticClass: "form-box" }, [
            _c("form", [
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "name" } }, [
                  _vm._v(_vm._s(_vm.$t("business.name"))),
                ]),
                _c("div", { staticStyle: { padding: "0 10px" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    attrs: { type: "text", name: "name" },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _c("span", { staticClass: "sex-box" }, [
                    _c("input", {
                      attrs: { id: "name", type: "hidden", name: "sex" },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            _vm.form.sex === "business.male"
                              ? "business.male"
                              : "business.female"
                          )
                        ) +
                        " "
                    ),
                    _c("span", {
                      staticClass: "up-btn",
                      on: {
                        click: function ($event) {
                          return _vm.changeSex()
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "down-btn",
                      on: {
                        click: function ($event) {
                          return _vm.changeSex()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "company" } }, [
                  _vm._v(_vm._s(_vm.$t("business.company"))),
                ]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.company,
                        expression: "form.company",
                      },
                    ],
                    attrs: { id: "company", type: "text", name: "company" },
                    domProps: { value: _vm.form.company },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "company", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "attr" } }, [
                  _vm._v(_vm._s(_vm.$t("business.industry"))),
                ]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.attr,
                        expression: "form.attr",
                      },
                    ],
                    attrs: { id: "attr", type: "text", name: "attr" },
                    domProps: { value: _vm.form.attr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "attr", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", { attrs: { for: "content" } }, [
                  _vm._v(_vm._s(_vm.$t("business.description"))),
                ]),
                _c("div", { staticStyle: { width: "518px" } }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.content,
                        expression: "form.content",
                      },
                    ],
                    staticStyle: { height: "214px" },
                    attrs: { id: "content", type: "text", name: "content" },
                    domProps: { value: _vm.form.content },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "content", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("business.contact")))]),
                this.$i18n.locale === "zh"
                  ? _c("div", { staticClass: "inline-label" }, [
                      _c("label", [
                        _c("span", [_vm._v(_vm._s(_vm.$t("business.wechat")))]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.phone,
                              expression: "form.phone",
                            },
                          ],
                          attrs: { id: "phone", type: "text", name: "phone" },
                          domProps: { value: _vm.form.phone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "phone", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "inline-label" }, [
                  _c("label", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("business.email")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email,
                          expression: "form.email",
                        },
                      ],
                      attrs: { id: "email", type: "text", name: "email" },
                      domProps: { value: _vm.form.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "form-item",
                  staticStyle: { "margin-top": "60px" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.baseUrl + "/common/uploads",
                            limit: 4,
                            "before-upload": _vm.beforeUpload,
                            multiple: true,
                            name: "files",
                            accept: ".doc,.pdf,.ppt,.jpg,.bmp,.gif",
                            "file-list": _vm.fileList,
                            "on-exceed": _vm.exceed,
                            "on-success": _vm.fileSuccess,
                          },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "uplaod-box" }, [
                              _vm._v(_vm._s(_vm.$t("business.add_file"))),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("business.condition_1")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("button", { on: { click: _vm.submit } }, [
              _vm._v(_vm._s(_vm.$t("business.submit"))),
            ]),
            _c("div", { staticStyle: { "margin-bottom": "50px" } }, [
              _vm._v(_vm._s(_vm.$t("business.forward"))),
            ]),
          ]),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }