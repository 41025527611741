<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content-box">
        <div class="banner-box">
          <img src="https://www.achencybe.com/img/pc/form/4-1.jpg" />
          <div class="banner-text">
            <div>{{ $t('business.des_1') }}</div>
            <p>
              {{ $t('business.des_2') }}
            </p>
          </div>
        </div>
        <div class="icon-box">
          <div v-for="item in iconList" :key="item.label">
            <img :src="item.icon" />
            <div>{{ $t(item.label) }}</div>
          </div>
        </div>
        <div class="tips-box">
          <p v-for="item in tipList" :key="item">{{ $t(item) }}</p>
        </div>
        <div class="form-box">
          <form>
            <div class="form-item">
              <label for="name">{{$t('business.name')}}</label>
              <div style="padding: 0 10px">
                <input type="text" name="name" v-model="form.name" />
                <span class="sex-box">
                  <input id="name" type="hidden" name="sex" />
                  {{ $t(form.sex === "business.male" ? "business.male" : "business.female") }}
                  <span
                    class="up-btn"
                    @click="changeSex()"
                  ></span>
                  <span
                    class="down-btn"
                    @click="changeSex()"
                  ></span>
                </span>
              </div>
            </div>
            <div class="form-item">
              <label for="company">{{$t('business.company')}}</label>
              <div>
                <input
                  id="company"
                  type="text"
                  name="company"
                  v-model="form.company"
                />
              </div>
            </div>
            <div class="form-item">
              <label for="attr">{{$t('business.industry')}}</label>
              <div>
                <input id="attr" type="text" name="attr" v-model="form.attr" />
              </div>
            </div>
            <div class="form-item">
              <label for="content">{{$t('business.description')}}</label>
              <div style="width: 518px">
                <textarea
                  id="content"
                  type="text"
                  name="content"
                  v-model="form.content"
                  style="height: 214px"
                />
              </div>
            </div>
            <div class="form-item">
              <label>{{$t('business.contact')}}</label>
              <div class="inline-label" v-if="this.$i18n.locale === 'zh'">
                <label>
                  <span>{{$t('business.wechat')}}</span>
                  <input
                    id="phone"
                    type="text"
                    name="phone"
                    v-model="form.phone"
                  />
                </label>
              </div>
              <div class="inline-label">
                <label>
                  <span>{{$t('business.email')}}</span>
                  <input
                    id="email"
                    type="text"
                    name="email"
                    v-model="form.email"
                  />
                </label>
              </div>
            </div>
            <div class="form-item" style="margin-top: 60px">
              <div>
                <el-upload
                  ref="upload"
                  class="upload-demo"
                  :action="baseUrl + '/common/uploads'"
                  :limit="4"
                  :before-upload="beforeUpload"
                  :multiple="true"
                  name="files"
                  accept=".doc,.pdf,.ppt,.jpg,.bmp,.gif"
                  :file-list="fileList"
                  :on-exceed="exceed"
                  :on-success="fileSuccess"
                >
                  <div>
                    <div class="uplaod-box">{{$t('business.add_file')}}</div>
                    <div slot="tip" class="el-upload__tip">
                      {{$t('business.condition_1')}}
                    </div>
                  </div>
                </el-upload>
              </div>
            </div>
          </form>
          <button @click="submit">{{$t('business.submit')}}</button>
          <div style="margin-bottom: 50px">{{$t('business.forward')}}</div>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import "../../scss/InvestorsFormPage.scss";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      iconList: [
        {
          icon: "https://www.achencybe.com/img/pc/form/4-2.png",
          label: "business.des_3",
        },
        {
          icon: "https://www.achencybe.com/img/pc/form/4-3.png",
          label:  "business.des_4",
        },
        {
          icon: "https://www.achencybe.com/img/pc/form/4-4.png",
          label:  "business.des_5",
        },
        {
          icon: "https://www.achencybe.com/img/pc/form/4-5.png",
          label:  "business.des_6",
        },
      ],
      tipList: [
        "business.des_7",
        "business.des_8",
        "business.des_9",
      ],
      form: {
        name: "",
        sex: "business.male",
        company: "",
        attr: "",
        content: "",
        phone: "",
        email: "",
        other: "",
      },
      fileList: [],
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
  methods: {
    beforeUpload(file) {
      const typeList = ["DOC", "PDF", "PPT", "JPG", "BMP", "GIF"];
      let testmsg = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toUpperCase();
      const extension = typeList.indexOf(testmsg) > -1;
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!extension) {
        alert("可用格式DOC、PDF、PPT、JPG、BMP、GIF");
      }
      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 15MB!",
          type: "warning",
        });
        return false;
      }
      return extension;
    },
    changeSex(){
      console.log(this.form.sex)
      this.form.sex = this.form.sex === 'business.male' ? 'business.female' : 'business.male'
    },
    exceed() {
      alert("最多上传4个文件");
    },
    fileSuccess(response, file, fileList) {
      if (response.code !== 0) {
        this.$message.error("上传失败");
        let index = this.fileList.findIndex((item) => {
          return item.uid === file.uid;
        });
        this.fileList.splice(index, 1);
      } else {
        this.fileList = fileList;
      }
    },
    submit() {
      const formHasVal = Object.keys(this.form).some((item) => {
        return this.form[item] && item !== "sex";
      });
      if (formHasVal || this.fileList.length) {
        const mobile = (value) => {
          return /^1[3-9]\d{9}$/.test(value);
        };
        const email = (value) => {
          return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
            value
          );
        };
        // if (this.form.phone && !mobile(this.form.phone)) {
        //   this.$message.warning("手机号不合法");
        //   return;
        // }
        if (this.form.email && !email(this.form.email)) {
          this.$message.warning("邮箱不合法");
          return;
        }
        let attachmentAddress = [],
          attachmentLocalAddress = [],
          attachmentName = [];
        this.fileList.forEach((item) => {
          attachmentAddress.push(item.response.urls);
          attachmentLocalAddress.push(item.response.fileNames);
          attachmentName.push(item.response.originalFilenames);
        });
        let data = {
          named: this.form.name,
          gender: this.form.sex,
          subordinateCompanies: this.form.company,
          industryAttributes: this.form.attr,
          descriptionCooperation: this.form.content,
          phone: this.form.phone,
          emailAddress: this.form.email,
          attachmentAddress: attachmentAddress.join(","),
          attachmentLocalAddress: attachmentLocalAddress.join(","),
          attachmentName: attachmentName.join(","),
        };
        this.$api.saveInvestors(data).then((res) => {
          if (res.code === 0) {
            this.$router.push({ name: "InvestorsFormResultPage" });
          }
        });
      } else {
        this.$message.warning("请先完成表单");
      }
    },
  },
};
</script>